var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "customer-box pa-8 mb-12"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-right pb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" メーカー ")]), _c('v-col', {
    staticClass: "flex-grow-1 pb-2",
    attrs: {
      "cols": "9"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "partner_0_id",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('v-select', {
          staticClass: "form-text",
          attrs: {
            "dense": "",
            "error-messages": errors,
            "error": errors.length !== 0,
            "hide-details": errors.length === 0,
            "outlined": "",
            "placeholder": "未選択",
            "items": _vm.partnerType0,
            "item-text": "name",
            "item-value": "id",
            "clearable": ""
          },
          model: {
            value: _vm.data.project.partner_0_id,
            callback: function callback($$v) {
              _vm.$set(_vm.data.project, "partner_0_id", $$v);
            },
            expression: "data.project.partner_0_id"
          }
        })];
      }
    }])
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-right pb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 発注会社 ")]), _c('v-col', {
    staticClass: "flex-grow-1 pb-2",
    attrs: {
      "cols": "9"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "partner_1_id",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('v-select', {
          staticClass: "form-text",
          attrs: {
            "dense": "",
            "error-messages": errors,
            "error": errors.length !== 0,
            "hide-details": errors.length === 0,
            "outlined": "",
            "placeholder": "未選択",
            "items": _vm.partnerType1,
            "item-text": "name",
            "item-value": "id",
            "clearable": ""
          },
          model: {
            value: _vm.data.project.partner_1_id,
            callback: function callback($$v) {
              _vm.$set(_vm.data.project, "partner_1_id", $$v);
            },
            expression: "data.project.partner_1_id"
          }
        })];
      }
    }])
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-right pb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 元請会社 ")]), _c('v-col', {
    staticClass: "flex-grow-1 pb-2",
    attrs: {
      "cols": "9"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "partner_2_id",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('v-select', {
          staticClass: "form-text",
          attrs: {
            "dense": "",
            "error-messages": errors,
            "error": errors.length !== 0,
            "hide-details": errors.length === 0,
            "outlined": "",
            "placeholder": "未選択",
            "items": _vm.partnerType2,
            "item-text": "name",
            "item-value": "id",
            "clearable": ""
          },
          model: {
            value: _vm.data.project.partner_2_id,
            callback: function callback($$v) {
              _vm.$set(_vm.data.project, "partner_2_id", $$v);
            },
            expression: "data.project.partner_2_id"
          }
        })];
      }
    }])
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-right pb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 現場立ち合い ")]), _c('v-col', {
    staticClass: "flex-grow-1 pb-2",
    attrs: {
      "cols": "9"
    }
  }, [_c('v-btn-toggle', {
    staticClass: "btn-toggle-worker-type",
    attrs: {
      "dense": ""
    },
    model: {
      value: _vm.data.project.on_site_monitoring,
      callback: function callback($$v) {
        _vm.$set(_vm.data.project, "on_site_monitoring", $$v);
      },
      expression: "data.project.on_site_monitoring"
    }
  }, [_c('v-btn', {
    attrs: {
      "value": 0
    }
  }, [_vm._v(" 有 ")]), _c('v-btn', {
    attrs: {
      "value": 1
    }
  }, [_vm._v(" 無 ")])], 1)], 1)], 1), _c('ServiceInfo', {
    attrs: {
      "data": _vm.data.project
    }
  }), _c('v-row', [_c('v-col', {
    staticClass: "form-header text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 現場に関するメモ ")]), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "9"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "general_guideline",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('v-textarea', {
          staticClass: "form-text",
          attrs: {
            "dense": "",
            "error-messages": errors,
            "error": errors.length !== 0,
            "hide-details": errors.length === 0,
            "outlined": "",
            "placeholder": "現場に関するメモを記入してください。"
          },
          model: {
            value: _vm.data.project.general_guideline,
            callback: function callback($$v) {
              _vm.$set(_vm.data.project, "general_guideline", $$v);
            },
            expression: "data.project.general_guideline"
          }
        })];
      }
    }])
  })], 1)], 1)], 1), _c('div', {
    ref: "work",
    attrs: {
      "id": "work"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "mb-8",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "text-title text-title-2 text-heading-2"
  }, [_vm._v("作業情報")]), _c('div', {
    staticClass: "horizontal-line"
  })])], 1)], 1), _vm.data.project.service_type_id === 0 ? [_c('TypeCoatingService', {
    attrs: {
      "data": _vm.data.svc_coating
    }
  })] : _vm.data.project.service_type_id === 1 ? [_c('TypeBuildingMaintenanceService', {
    attrs: {
      "data": _vm.data.svc_bldg_mnt
    }
  })] : _vm.data.project.service_type_id > 1 ? [_c('TypeRenewalService', {
    attrs: {
      "data": _vm.data.svc_other
    }
  })] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }