<template>
  <div class="customer-box pa-8 mb-12">
    <v-row align="center">
      <v-col cols="2" class="form-header text-right pb-2">
        顧客名
      </v-col>
      <v-col cols="9" class="flex-grow-1 pb-2">
        <validation-provider
          v-slot="{ errors }"
          name="names"
          rules="required|max:50"
        >
          <v-text-field
            class="form-text"
            v-model="data.name"
            dense
            :error-messages="errors"
            :error="errors.length !== 0"
            :hide-details="errors.length === 0"
            outlined
            placeholder="顧客名を入力してください"
          ></v-text-field>
        </validation-provider>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="2" class="form-header text-right pb-2">
        顧客名（かな）
      </v-col>
      <v-col cols="9" class="flex-grow-1 pb-2">
        <validation-provider
          v-slot="{ errors }"
          name="furigana_name"
          rules="max:50"
        >
          <v-text-field
            class="form-text"
            v-model="data.furigana_name"
            dense
            :error-messages="errors"
            :error="errors.length !== 0"
            :hide-details="errors.length === 0"
            outlined
            placeholder="カタカナ"
          ></v-text-field>
        </validation-provider>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="2" class="form-header text-right pb-2">
        郵便番号
      </v-col>
      <v-col cols="9" class="flex-grow-1 pb-2">
        <validation-provider v-slot="{ errors }" name="postcode" rules="">
          <v-text-field
            class="form-text"
            v-model="data.postcode"
            dense
            :error-messages="errors"
            :error="errors.length !== 0"
            :hide-details="errors.length === 0"
            outlined
            placeholder="000-0000"
            v-mask="'###-####'"
            @input="fetchPostcodeData()"
            max="8"
          ></v-text-field>
        </validation-provider>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="2" class="form-header text-right pb-2">
        都道府県
      </v-col>
      <v-col cols="9" class="flex-grow-1 pb-2">
        <v-select
          class="form-text"
          v-model="data.prefecture_id"
          item-text="name"
          item-value="id"
          :items="prefectures"
          dense
          hide-details
          outlined
          placeholder="都道府県"
          :loading="dataLoading"
        ></v-select>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="2" class="form-header text-right pb-2">
        市区町村
      </v-col>
      <v-col cols="9" class="flex-grow-1 pb-2">
        <v-text-field
          class="form-text"
          v-model="data.city"
          dense
          hide-details
          outlined
          placeholder="顧客名を入力してください"
          :loading="dataLoading"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="2" class="form-header text-right pb-2">
        住所
      </v-col>
      <v-col cols="9" class="flex-grow-1 pb-2">
        <validation-provider v-slot="{ errors }" name="address" rules="">
          <v-text-field
            class="form-text"
            v-model="data.address"
            dense
            :error-messages="errors"
            :error="errors.length !== 0"
            :hide-details="errors.length === 0"
            outlined
            placeholder="住所"
          ></v-text-field>
        </validation-provider>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col cols="2" class="form-header text-right pb-2">
        電話番号
      </v-col>
      <v-col cols="9" class="flex-grow-1 pb-2">
        <validation-provider v-slot="{ errors }" name="phone1" rules="">
          <v-text-field
            class="form-text"
            v-model="data.phone1"
            dense
            :error-messages="errors"
            :error="errors.length !== 0"
            :hide-details="errors.length === 0"
            outlined
            placeholder="00000000000"
          ></v-text-field>
        </validation-provider>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col cols="2" class="form-header text-right">
        顧客メモ
      </v-col>
      <v-col cols="9" class="flex-grow-1">
        <validation-provider v-slot="{ errors }" name="memo" rules="max:500">
          <v-textarea
            class="form-text"
            v-model="data.memo"
            dense
            :error-messages="errors"
            :error="errors.length !== 0"
            :hide-details="errors.length === 0"
            outlined
            placeholder="自由入力"
            row-height="4"
          ></v-textarea>
        </validation-provider>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import postal_code from 'japan-postal-code'

export default {
  name: 'CreateCustomer',
  props: {
    data: {
      type: Object,
      required: true
    },
    prefectures: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      dataLoading: false
    }
  },
  methods: {
    fetchPostcodeData: debounce(function() {
      this.dataLoading = true
      postal_code.get(this.data.postcode, address => {
        let selected_prefecture = this.prefectures.find(
          prefecture => prefecture.name === address.prefecture
        )
        if (selected_prefecture) {
          this.data.prefecture = selected_prefecture.name
          this.data.prefecture_id = selected_prefecture.id
          this.data.city = address.city
        }
      })
      this.dataLoading = false
    }, 500)
  }
}
</script>
<style lang="scss" src="./Customer.scss" scoped></style>
