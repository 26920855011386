<template>
  <validation-observer ref="observer">
    <form @submit.prevent="save">
      <div class="pa-9 pa-md-6 pa-lg-9">
        <v-container>
          <v-row align="center" id="customer" ref="customer">
            <v-col cols="12">
              <div class="customer-name">
                <h3 class="page-title font-weight-bold mb-0">顧客の新規追加</h3>
              </div>
            </v-col>
            <v-col cols="12" class="mb-8">
              <div class="text-title text-title-2 text-heading-2">
                顧客 / 台帳
              </div>
              <div class="horizontal-line"></div>
            </v-col>
          </v-row>

          <Customer :data.sync="data.customer" :prefectures="allPrefectures" />

          <v-row align="center" id="property" ref="property">
            <v-col cols="12" class="mb-5">
              <div class="text-title text-title-2 text-heading-2">物件情報</div>
              <div class="horizontal-line"></div>
            </v-col>
          </v-row>

          <v-container>
            <v-row>
              <v-col v-for="(item, key) in data.sites" :key="`sitename-${key}`" cols="auto" class="pb-0 pr-0">
                <v-btn :class="
                  tabActive === key
                    ? 'btn-property-tab-active'
                    : 'btn-property-tab'
                " @click="tabActive = key" text depressed>
                  {{  getSiteName(item)  }}
                </v-btn>
              </v-col>
              <v-col cols="auto" class="pb-0">
                <v-btn color="#757575" min-width="12" max-width="12" text @click="addNewSite()">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>

          <template v-for="(site, key) in data.sites">
            <Site v-if="tabActive === key" :key="`site-${key}`" :data.sync="site" :prefectures="allPrefectures"
              :services="allServices" />
          </template>
        </v-container>
      </div>

      <div class="intersection-helper" v-intersect="onIntersect"></div>

      <div class="create-task-footer px-6" :class="!isIntersecting ? 'shadow' : ''">
        <v-row>
          <v-col cols="auto" class="flex-grow-1">
            <v-btn class="mr-3" :color="
              on.customer
                ? 'rgba(79, 85, 167, 0.45)'
                : 'rgba(79, 85, 167, 0.1)'
            " depressed rounded @click="$vuetify.goTo('#customer', { offset: offset })">
              顧客
            </v-btn>
            <v-btn class="mr-3" :color="
              on.property
                ? 'rgba(79, 85, 167, 0.45)'
                : 'rgba(79, 85, 167, 0.1)'
            " depressed rounded @click="$vuetify.goTo('#property', { offset: offset })">
              物件
            </v-btn>
            <v-btn :color="
              on.work ? 'rgba(79, 85, 167, 0.45)' : 'rgba(79, 85, 167, 0.1)'
            " depressed rounded @click="$vuetify.goTo('#work', { offset: offset })">
              作業
            </v-btn>
          </v-col>

          <v-col cols="auto">
            <v-btn @click="$router.push('/customer/list')" text :loading="loading">
              キャンセル
            </v-btn>
          </v-col>

          <v-col cols="auto">
            <v-btn @click="save" color="#4F55A7" class="white--text" width="100" :loading="loading">
              登録
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import Customer from '@/views/customer/Create/Customer.vue'
import Site from '@/views/customer/Create/Site'
import { mapGetters } from 'vuex'
import emptySiteData from './emptySiteData'
import { normalize } from '@geolonia/normalize-japanese-addresses'

export default {
  name: 'Create',
  components: { Customer, Site },
  computed: {
    ...mapGetters(['allServices', 'allPrefectures', 'allPartners'])
  },
  async created() {
    let param = {
      partner: {
        type: 'all'
      }
    }
    await this.$store.dispatch('SERVICE_GET_ALL')
    await this.$store.dispatch('PREFECTURE_GET_ALL')
    await this.$store.dispatch('PARTNER_GET_ALL', param.partner)
  },
  data() {
    return {
      tabActive: 0,
      offset: 80,
      data: {
        customer: {
          name: '',
          furigana_name: '',
          postcode: '',
          prefecture_id: '',
          city: '',
          address: '',
          phone1: '',
          memo: '',
          lat: null,
          lng: null
        },
        sites: [{ ...emptySiteData }]
      },
      on: {
        customer: false,
        property: false,
        work: false
      },
      isIntersecting: false,
      loading: false
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll)
  },
  destroy() {
    window.removeEventListener('scroll', this.updateScroll)
  },
  methods: {
    async save() {
      this.$refs.observer.validate().then(async success => {
        if (!success) {
          return
        }
        await this.fillLatLong()
        let formData = this.sanitizeFormData()
        this.loading = true
        this.$store
          .dispatch('CUSTOMER_CREATE', formData)
          .then(
            response => {
              if (response.data.status === 'success') {
                this.$router.push({
                  path: '/customer/list',
                  query: {
                    status: 'success',
                    message: response.data.data.message
                  }
                })
              }
            },
            error => {
              throw error
            }
          )
          .finally(() => {
            this.loading = false
          })
      })
    },
    updateScroll() {
      let scrollPosition = window.scrollY
      this.on = { customer: false, property: false, work: false }

      if (scrollPosition >= 1915) {
        this.on.work = true
      } else if (scrollPosition >= 684) {
        this.on.property = true
      } else if (scrollPosition >= 46) {
        this.on.customer = true
      }
    },
    addNewSite() {
      this.data.sites.push({ ...emptySiteData })
    },
    getSiteName(site) {
      return site.name || '未登録物件'
    },
    onIntersect(entries) {
      this.isIntersecting = entries[0].isIntersecting
    },
    async fillLatLong() {
      let prefecture = this.allPrefectures.find(
        v => v.id === this.data.customer.prefecture_id
      )
      await normalize(
        prefecture?.name + this.data.customer.city + this.data.customer.address
      ).then(result => {
        this.data.customer.lng = result.lng
        this.data.customer.lat = result.lat
      })

      for (const site of this.data.sites) {
        let prefecture = this.allPrefectures.find(
          v => v.id === site.prefecture_id
        )
        await normalize(prefecture?.name + site.city + site.address).then(
          result => {
            // site.lng = result.lng
            // site.lat = result.lat
            if (result.lat && result.lng) {
              site.lng = result.lng
              site.lat = result.lat
            } else if (result[0]?.lat && result[0]?.lng) {
              site.lng = result.lng
              site.lat = result.lat
            }
          }
        )
      }
    },
    sanitizeFormData() {
      let formData = {}
      formData.customer = this.data.customer

      let sites = [...this.data.sites]
      formData.sites = sites.map(site => {
        if (site.project.service_type_id === 0) {
          delete site.svc_bldg_mnt
          delete site.svc_other
        } else if (site.project.service_type_id === 1) {
          delete site.svc_coating
          delete site.svc_other
        } else if (site.project.service_type_id > 1) {
          delete site.svc_coating
          delete site.svc_bldg_mnt
        } else {
          delete site.svc_coating
          delete site.svc_bldg_mnt
          delete site.svc_other
        }
        return site
      })
      return formData
    }
  }
}
</script>

<style lang="scss" src="./Create.scss" scoped>
</style>
