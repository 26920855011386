export default [
  {
    name: '共用部洗浄・床洗浄ワックス',
    type: 'floor',
    fields: [
      {
        key: 'prep',
        label: '清掃前準備',
        type: 'checkbox',
        values: [
          {
            text: 'ダスター掛け',
            value: '0'
          },
          {
            text: '掃除機掛け',
            value: '1'
          },
          {
            text: '養生（出入り口マット）',
            value: '2'
          },
          {
            id: 1,
            extra_type: 'with_text',
            text: 'その他',
            value: '3'
          }
        ]
      },
      {
        key: 'detergent',
        label: '使用洗剤',
        type: 'checkbox',
        values: [
          {
            text: 'ハイフレッシュ',
            value: '0'
          },
          {
            text: '支給洗剤',
            value: '1'
          },
          {
            id: 1,
            extra_type: 'with_text',
            text: 'その他',
            value: '2'
          }
        ]
      },
      {
        key: 'detergent_method',
        label: '洗剤塗布方法',
        type: 'checkbox',
        values: [
          {
            text: '塗布',
            value: '0'
          },
          {
            text: 'ジョウロ撒き',
            value: '1'
          },
          {
            text: 'ポリッシャータンク',
            value: '2'
          },
          {
            extra_type: 'with_text',
            text: 'その他',
            value: '3'
          }
        ]
      },
      {
        key: 'cleaning_method',
        label: '基本洗浄方法',
        type: 'checkbox',
        values: [
          {
            text: 'ポリッシャータンク',
            value: '0'
          },
          {
            text: '自洗機',
            value: '1'
          },
          {
            text: '掃き・拭き',
            value: '2'
          },
          {
            text: 'バルチャー・ホース水撒',
            value: '3'
          },
          {
            text: 'バルチャー・高圧洗浄',
            value: '4'
          },
          {
            extra_type: 'with_text',
            text: 'その他',
            value: '5'
          }
        ]
      },
      {
        key: 'polisher',
        label: '使用ポリッシャー',
        type: 'checkbox',
        values: [
          {
            text: '12in',
            value: '0'
          },
          {
            text: '14in',
            value: '1'
          },
          {
            text: '16in',
            value: '2'
          },
          {
            text: '38バルチャー',
            value: '3'
          },
          {
            text: '48バルチャー',
            value: '4'
          },
          {
            extra_type: 'with_text',
            text: 'その他',
            value: '5'
          }
        ]
      },
      {
        key: 'cleaning_machine',
        label: '使用自洗機',
        type: 'checkbox',
        values: [
          {
            text: 'アマノ（SE430）',
            value: '0'
          },
          {
            text: 'TENNANT T3',
            value: '1'
          },
          {
            text: 'スクラブメイト ミニ450B T3',
            value: '2'
          },
          {
            extra_type: 'with_text',
            text: 'その他',
            value: '3'
          }
        ]
      },
      {
        key: 'pat',
        label: '使用パット',
        type: 'checkbox',
        values: [
          {
            text: '白',
            value: '0',
            color: '#FFFFFF'
          },
          {
            text: '赤',
            value: '1',
            color: '#E74C3C'
          },
          {
            text: '青',
            value: '2',
            color: '#5DADE2'
          },
          {
            text: '緑',
            value: '3',
            color: '#2ECC71'
          },
          {
            text: '茶',
            value: '4',
            color: '#AE7300'
          },
          {
            text: '黒',
            value: '5',
            color: '#333333'
          },
          {
            extra_type: 'with_text',
            text: 'その他',
            value: '6',
            color: '#000000'
          }
        ]
      },
      {
        key: 'certain_place_method',
        label: '場所指定洗浄方法',
        type: 'checkbox',
        values: [
          {
            text: 'ポリッシャータンク',
            value: '0'
          },
          {
            text: '自洗機',
            value: '1'
          },
          {
            text: '掃き・拭き',
            value: '2'
          },
          {
            text: 'バルチャー・ホース水撒',
            value: '3'
          },
          {
            text: 'バルチャー・高圧洗浄',
            value: '4'
          },
          {
            extra_type: 'with_text',
            text: 'その他',
            value: '5'
          }
        ]
      },
      {
        key: 'certain_place_explanation',
        label: '指定場所',
        type: 'textarea',
        values: '指定場所を記入してください。'
      },
      {
        key: 'waxing_place',
        label: '範囲指定',
        type: 'checkbox-group',
        fields: [
          {
            column: 'waxing_direction',
            key: 'waxing_place_all_direction',
            label: '全体',
            type: 'checkbox',
            values: [
              {
                text: '1層',
                value: '0'
              },
              {
                text: '2層',
                value: '1'
              },
              {
                text: '水拭き仕上げ',
                value: '2'
              },
              {
                text: 'バフ仕上げ',
                value: '3'
              }
            ]
          },
          {
            key: 'waxing_place_all',
            label: '',
            type: 'text'
          },
          {
            column: 'waxing_direction',
            key: 'waxing_place_part_1_direction',
            label: '部分1',
            type: 'checkbox',
            values: [
              {
                text: '1層',
                value: '0'
              },
              {
                text: '2層',
                value: '1'
              },
              {
                text: '水拭き仕上げ',
                value: '2'
              },
              {
                text: 'バフ仕上げ',
                value: '3'
              }
            ]
          },
          {
            key: 'waxing_place_part_1',
            label: '',
            type: 'text'
          },
          {
            column: 'waxing_direction',
            key: 'waxing_place_part_2_direction',
            label: '部分2',
            type: 'checkbox',
            values: [
              {
                text: '1層',
                value: '0'
              },
              {
                text: '2層',
                value: '1'
              },
              {
                text: '水拭き仕上げ',
                value: '2'
              },
              {
                text: 'バフ仕上げ',
                value: '3'
              }
            ]
          },
          {
            key: 'waxing_place_part_2',
            label: '',
            type: 'text'
          },
          {
            column: 'waxing_direction',
            key: 'waxing_place_part_3_direction',
            label: '部分3',
            type: 'checkbox',
            values: [
              {
                text: '1層',
                value: '0'
              },
              {
                text: '2層',
                value: '1'
              },
              {
                text: '水拭き仕上げ',
                value: '2'
              },
              {
                text: 'バフ仕上げ',
                value: '3'
              }
            ]
          },
          {
            key: 'waxing_place_part_3',
            label: '',
            type: 'text'
          },
          {
            column: 'waxing_direction',
            key: 'waxing_place_part_4_direction',
            label: '部分4',
            type: 'checkbox',
            values: [
              {
                text: '1層',
                value: '0'
              },
              {
                text: '2層',
                value: '1'
              },
              {
                text: '水拭き仕上げ',
                value: '2'
              },
              {
                text: 'バフ仕上げ',
                value: '3'
              }
            ]
          },
          {
            key: 'waxing_place_part_4',
            label: '',
            type: 'text'
          }
        ]
      },
      {
        key: 'waxing_product',
        label: '使用ワックス',
        type: 'checkbox',
        values: [
          {
            text: 'PPコート',
            value: '0'
          },
          {
            text: 'バックスM',
            value: '1'
          },
          {
            text: 'ニトリワックス',
            value: '2'
          },
          {
            text: '余りワックスを先行して使用する',
            value: '3'
          },
          {
            extra_type: 'with_text',
            text: 'その他',
            value: '4'
          }
        ]
      },
      {
        key: 'waxing_rule',
        label: 'ワックスに関する注意事項',
        type: 'textarea',
        values: 'ワックスに関する注意事項を記入してください。'
      }
    ]
  },
  {
    name: '窓ガラス清掃',
    type: 'window',
    fields: [
      {
        key: 'stepladder',
        label: '使用脚立',
        type: 'checkbox',
        values: [
          {
            text: '90(3尺)',
            value: '0'
          },
          {
            text: '120(4尺)',
            value: '1'
          },
          {
            text: '150(5尺)',
            value: '2'
          },
          {
            text: '180(6尺)',
            value: '3'
          },
          {
            text: '210(7尺)',
            value: '4'
          },
          {
            text: '270(9尺)',
            value: '5'
          },
          {
            extra_type: 'with_text',
            text: 'その他',
            value: '6'
          }
        ]
      },
      {
        key: 'extension_pole',
        label: '延長ポール',
        type: 'checkbox',
        values: [
          {
            text: 'ショートポール',
            value: '0'
          },
          {
            text: 'ロングポール',
            value: '1'
          },
          {
            text: 'なし',
            value: '2'
          },
          {
            extra_type: 'with_text',
            text: 'その他',
            value: '3'
          }
        ]
      },
      {
        key: 'safety_helmet',
        label: 'ヘルメット',
        type: 'checkbox',
        values: [
          {
            text: 'あり',
            value: '0'
          },
          {
            text: 'なし',
            value: '1'
          },
          {
            extra_type: 'with_text',
            text: 'その他',
            value: '2'
          }
        ]
      },
      {
        key: 'safety_belt',
        label: '安全帯',
        type: 'checkbox',
        values: [
          {
            text: 'あり',
            value: '0'
          },
          {
            text: 'なし',
            value: '1'
          },
          {
            extra_type: 'with_text',
            text: 'その他',
            value: '2'
          }
        ]
      },
      {
        key: 'outside_radio',
        label: '外面',
        type: 'radio',
        values: [
          {
            text: 'あり',
            values: '0'
          },
          {
            text: 'なし',
            value: '6'
          }
        ]
      },
      {
        key: 'outside',
        label: '',
        type: 'checkbox',
        conditional: {
          field: 'data.window.outside_radio',
          operator: '===',
          value: 0
        },
        values: [
          {
            text: '脚立',
            value: '1'
          },
          {
            text: '乗り出し',
            value: '2'
          },
          {
            text: 'ブランコ',
            value: '3'
          },
          {
            text: '高所作業車',
            value: '4'
          },
          {
            extra_type: 'with_text',
            text: 'その他',
            value: '5'
          }
        ]
      },
      {
        key: 'inside',
        label: '内面',
        type: 'checkbox',
        values: [
          {
            text: 'あり',
            value: '0'
          },
          {
            text: 'なし',
            value: '1'
          },
          {
            text: 'キレイな箇所は作業不要',
            value: '2'
          },
          {
            extra_type: 'with_text',
            text: 'その他',
            value: '3'
          }
        ]
      },
      {
        key: 'certain_window_method',
        label: '場所指定特記事項',
        type: 'checkbox',
        values: [
          {
            text: '外面のみ',
            value: '0'
          },
          {
            text: '内面のみ',
            value: '1'
          },
          {
            text: 'シールあと除去',
            value: '2'
          },
          {
            text: '手垢拭き程度',
            value: '3'
          },
          {
            extra_type: 'with_text',
            text: 'その他',
            value: '4'
          }
        ]
      },
      {
        key: 'certain_window_explanation',
        label: '指定場所',
        type: 'textarea',
        values: '指定場所を記入してください。'
      },
      {
        key: 'certain_window_rule',
        label: '注意事項',
        type: 'textarea',
        values: '注意事項を記入してください。'
      }
    ]
  },
  {
    name: 'カーペット洗浄',
    type: 'carpet',
    fields: [
      {
        key: 'prep',
        label: '作業前工程',
        type: 'checkbox',
        values: [
          {
            text: 'アップライト掛け',
            value: '0'
          },
          {
            text: '掃除機掛け',
            value: '1'
          },
          {
            text: 'アップライト仕上げ',
            value: '2'
          },
          {
            extra_type: 'with_text',
            text: 'その他',
            value: '3'
          }
        ]
      },
      {
        key: 'pre_task',
        label: '前処理作業',
        type: 'checkbox',
        values: [
          {
            text: 'パワーミスター（パワーバースト）',
            value: '0'
          },
          {
            text: 'なし',
            value: '1'
          },
          {
            extra_type: 'with_text',
            text: 'その他',
            value: '2'
          }
        ]
      },
      {
        key: 'washing',
        label: '洗浄作業',
        type: 'checkbox',
        values: [
          {
            text: 'バルチャー洗浄（シャンプー）',
            value: '0'
          },
          {
            text: 'ヤーンパット',
            value: '1'
          },
          {
            text: 'なし',
            value: '2'
          },
          {
            extra_type: 'with_text',
            text: 'その他',
            value: '3'
          }
        ]
      },
      {
        key: 'machine',
        label: '使用バルチャー',
        type: 'checkbox',
        values: [
          {
            text: '38バルチャー',
            value: '0'
          },
          {
            text: '48バルチャー',
            value: '1'
          },
          {
            extra_type: 'with_text',
            text: 'その他',
            value: '2'
          }
        ]
      },
      {
        key: 'stain_removal',
        label: '染み抜き',
        type: 'checkbox',
        values: [
          {
            text: 'あり',
            value: '0'
          },
          {
            text: 'なし',
            value: '1'
          },
          {
            extra_type: 'with_text',
            text: 'その他',
            value: '2'
          }
        ]
      },
      {
        key: 'wastewater',
        label: '汚水回収',
        type: 'checkbox',
        values: [
          {
            id: 0,
            text: 'スチームエクストラクター',
            value: '0'
          },
          {
            id: 1,
            text: 'マイクロファイバーパット',
            value: '1'
          },
          {
            id: 2,
            text: 'なし',
            value: '2'
          },
          {
            id: 3,
            extra_type: 'with_text',
            text: 'その他',
            value: '3'
          }
        ]
      }
    ]
  }
]
