var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "info-box mb-8"
  }, _vm._l(_vm.fields, function (item, index) {
    return _c('v-row', {
      key: "form-service-type-renewal-".concat(index),
      attrs: {
        "align": "start"
      }
    }, [_c('v-col', {
      staticClass: "form-header text-body-1 text-right",
      attrs: {
        "cols": "2"
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('v-col', {
      attrs: {
        "cols": "9"
      }
    }, [item.type === 'textarea' ? [_c('validation-provider', {
      attrs: {
        "name": item.key,
        "rules": ""
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref) {
          var errors = _ref.errors;
          return [_c('v-textarea', {
            staticClass: "form-text",
            attrs: {
              "dense": "",
              "outlined": "",
              "placeholder": item.values,
              "counter": "",
              "error-messages": errors,
              "error": errors.length !== 0,
              "maxlength": "500"
            },
            scopedSlots: _vm._u([{
              key: "counter",
              fn: function fn(_ref2) {
                var props = _ref2.props;
                return [_vm._v(" " + _vm._s(props.value) + " / " + _vm._s(props.max) + " 文字 ")];
              }
            }], null, true),
            model: {
              value: _vm.data[item.key],
              callback: function callback($$v) {
                _vm.$set(_vm.data, item.key, $$v);
              },
              expression: "data[item.key]"
            }
          })];
        }
      }], null, true)
    })] : _vm._e()], 2)], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }