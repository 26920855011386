<template>
  <div>
    <SiteDetail :data="data" :services="services" :prefectures="prefectures" />

    <v-row align="center">
      <v-col cols="auto">
        <div class="customer-name py-5 pt-10">
          {{ serviceName }}
        </div>
      </v-col>
    </v-row>

    <SiteInfo :data="data" />
  </div>
</template>

<script>
import SiteDetail from '@/views/customer/Create/Site/SiteDetail'
import SiteInfo from '@/views/customer/Create/Site/SiteInfo'
export default {
  name: 'CreateSite',
  components: { SiteInfo, SiteDetail },
  props: {
    data: {
      type: Object,
      required: true
    },
    prefectures: {
      type: Array,
      required: true
    },
    services: {
      type: Array,
      required: true
    }
  },
  computed: {
    serviceName() {
      return this.data.service?.name || ''
    }
  }
}
</script>
<style lang="scss" src="./Site.scss" scoped></style>
