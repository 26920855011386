var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "customer-box pa-8 mb-5"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-right pb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 物件ID ")]), _c('v-col', {
    staticClass: "flex-grow-1 pb-2",
    attrs: {
      "cols": "4"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "site_id",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('v-text-field', {
          staticClass: "form-text",
          attrs: {
            "dense": "",
            "error-messages": errors,
            "error": errors.length !== 0,
            "hide-details": errors.length === 0,
            "outlined": "",
            "placeholder": "物件ID"
          },
          model: {
            value: _vm.data.site_id,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "site_id", $$v);
            },
            expression: "data.site_id"
          }
        })];
      }
    }])
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-right pb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 物件名 ")]), _c('v-col', {
    staticClass: "flex-grow-1 pb-2",
    attrs: {
      "cols": "9"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "name",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('v-text-field', {
          staticClass: "form-text",
          attrs: {
            "dense": "",
            "error-messages": errors,
            "error": errors.length !== 0,
            "hide-details": errors.length === 0,
            "outlined": "",
            "placeholder": "物件名を入力してください"
          },
          model: {
            value: _vm.data.name,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "name", $$v);
            },
            expression: "data.name"
          }
        })];
      }
    }])
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-right pb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 物件名（かな） ")]), _c('v-col', {
    staticClass: "flex-grow-1 pb-2",
    attrs: {
      "cols": "9"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "furigana_name",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('v-text-field', {
          staticClass: "form-text",
          attrs: {
            "dense": "",
            "error-messages": errors,
            "error": errors.length !== 0,
            "hide-details": errors.length === 0,
            "outlined": "",
            "placeholder": "物件名（かな）を入力してください"
          },
          model: {
            value: _vm.data.furigana_name,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "furigana_name", $$v);
            },
            expression: "data.furigana_name"
          }
        })];
      }
    }])
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-right pb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 郵便番号 ")]), _c('v-col', {
    staticClass: "flex-grow-1 pb-2",
    attrs: {
      "cols": "9"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "postcode",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('v-text-field', {
          directives: [{
            name: "mask",
            rawName: "v-mask",
            value: '###-####',
            expression: "'###-####'"
          }],
          staticClass: "form-text",
          attrs: {
            "dense": "",
            "error-messages": errors,
            "error": errors.length !== 0,
            "hide-details": errors.length === 0,
            "outlined": "",
            "placeholder": "000-0000",
            "type": "text"
          },
          on: {
            "input": _vm.fetchPostcodeData
          },
          model: {
            value: _vm.data.postcode,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "postcode", $$v);
            },
            expression: "data.postcode"
          }
        })];
      }
    }])
  })], 1), _c('v-col', {
    staticClass: "form-header text-right pb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 都道府県 ")]), _c('v-col', {
    staticClass: "flex-grow-1 pb-2",
    attrs: {
      "cols": "9"
    }
  }, [_c('v-select', {
    staticClass: "form-text",
    attrs: {
      "items": _vm.prefectures,
      "item-text": "name",
      "item-value": "id",
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "placeholder": "都道府県"
    },
    model: {
      value: _vm.data.prefecture_id,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "prefecture_id", $$v);
      },
      expression: "data.prefecture_id"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-right pb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 市区町村 ")]), _c('v-col', {
    staticClass: "flex-grow-1 pb-2",
    attrs: {
      "cols": "9"
    }
  }, [_c('v-text-field', {
    staticClass: "form-text",
    attrs: {
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "placeholder": "市区町村"
    },
    model: {
      value: _vm.data.city,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "city", $$v);
      },
      expression: "data.city"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-right pb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 住所 ")]), _c('v-col', {
    staticClass: "flex-grow-1 pb-2",
    attrs: {
      "cols": "9"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "address",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('v-text-field', {
          staticClass: "form-text",
          attrs: {
            "dense": "",
            "error-messages": errors,
            "error": errors.length !== 0,
            "hide-details": errors.length === 0,
            "outlined": "",
            "placeholder": "住所"
          },
          model: {
            value: _vm.data.address,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "address", $$v);
            },
            expression: "data.address"
          }
        })];
      }
    }])
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-right pb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 物件メモ ")]), _c('v-col', {
    staticClass: "flex-grow-1 pb-2",
    attrs: {
      "cols": "9"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "memo",
      "rules": "max:500"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('v-text-field', {
          staticClass: "form-text",
          attrs: {
            "dense": "",
            "error-messages": errors,
            "error": errors.length !== 0,
            "hide-details": errors.length === 0,
            "outlined": "",
            "placeholder": "自由入力"
          },
          model: {
            value: _vm.data.memo,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "memo", $$v);
            },
            expression: "data.memo"
          }
        })];
      }
    }])
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" サービス ")]), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "9"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "service_type_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('v-select', {
          staticClass: "form-text",
          attrs: {
            "items": _vm.services,
            "item-text": "name",
            "item-value": "id",
            "dense": "",
            "error-messages": errors,
            "error": errors.length !== 0,
            "hide-details": errors.length === 0,
            "outlined": "",
            "placeholder": "選択してください"
          },
          model: {
            value: _vm.data.project.service_type_id,
            callback: function callback($$v) {
              _vm.$set(_vm.data.project, "service_type_id", $$v);
            },
            expression: "data.project.service_type_id"
          }
        })];
      }
    }])
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }