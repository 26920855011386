<template>
  <v-container class="info-box mb-8">
    <v-row
      v-for="(item, index) in fields"
      :key="`form-service-type-renewal-${index}`"
      align="start"
    >
      <v-col cols="2" class="form-header text-body-1 text-right">
        {{ item.label }}
      </v-col>
      <v-col cols="9">
        <template v-if="item.type === 'textarea'">
          <validation-provider v-slot="{ errors }" :name="item.key" rules="">
            <v-textarea
              dense
              outlined
              class="form-text"
              v-model="data[item.key]"
              :placeholder="item.values"
              counter
              :error-messages="errors"
              :error="errors.length !== 0"
              maxlength="500"
            >
              <template v-slot:counter="{ props }">
                {{ props.value }} / {{ props.max }} 文字
              </template>
            </v-textarea>
          </validation-provider>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'TypeRenewalService',
  props: {
    data: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  data() {
    return {
      fields: [
        {
          key: 'task_description_1',
          label: '作業内容1',
          type: 'textarea',
          values: '記入してください。'
        },
        {
          key: 'task_description_2',
          label: '作業内容2',
          type: 'textarea',
          values: '記入してください。'
        },
        {
          key: 'task_description_3',
          label: '作業内容3',
          type: 'textarea',
          values: '記入してください。'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  &-header {
    color: #393a00;
    font-size: 1.4rem !important;
  }

  &-text {
    box-shadow: unset;
    ::v-deep {
      .v-input__slot {
        background: #ffffff;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;

        ::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          font-size: 1.4rem;
        }
        ::-moz-placeholder {
          /* Firefox 19+ */
          font-size: 1.4rem;
        }
        :-ms-input-placeholder {
          /* IE 10+ */
          font-size: 1.4rem;
        }
        :-moz-placeholder {
          /* Firefox 18- */
          font-size: 1.4rem;
        }
      }
      .v-text-field__details {
        font-size: 1.4rem;
      }
    }
  }
}

.info-box {
  background: #f8f9ff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 3.2rem;

  &-headline {
    color: #4f55a7;
    font-size: 18px;

    &-sub {
      color: #474747;
      font-size: 18px;
      font-weight: bold;
    }
  }

  &-dot {
    background-color: #4f55a7;
    height: 3px;
    width: 3px;
    border-radius: 50%;
    display: inline-block;
  }

  &-work-information {
    &-headline {
      color: #333333;

      &.top {
        font-size: 18px;
        font-weight: 700;
      }

      &.sub {
        font-size: 14px;
      }
    }

    &-content {
      &-label {
        color: #828282;
        font-size: 16px;
      }

      &-content {
        color: #333333;
        font-size: 16px;
      }
    }
  }
}
</style>
