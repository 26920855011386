var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('SiteDetail', {
    attrs: {
      "data": _vm.data,
      "services": _vm.services,
      "prefectures": _vm.prefectures
    }
  }), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "customer-name py-5 pt-10"
  }, [_vm._v(" " + _vm._s(_vm.serviceName) + " ")])])], 1), _c('SiteInfo', {
    attrs: {
      "data": _vm.data
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }