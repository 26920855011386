var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "observer"
  }, [_c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.save.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "pa-9 pa-md-6 pa-lg-9"
  }, [_c('v-container', [_c('v-row', {
    ref: "customer",
    attrs: {
      "align": "center",
      "id": "customer"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "customer-name"
  }, [_c('h3', {
    staticClass: "page-title font-weight-bold mb-0"
  }, [_vm._v("顧客の新規追加")])])]), _c('v-col', {
    staticClass: "mb-8",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "text-title text-title-2 text-heading-2"
  }, [_vm._v(" 顧客 / 台帳 ")]), _c('div', {
    staticClass: "horizontal-line"
  })])], 1), _c('Customer', {
    attrs: {
      "data": _vm.data.customer,
      "prefectures": _vm.allPrefectures
    },
    on: {
      "update:data": function updateData($event) {
        return _vm.$set(_vm.data, "customer", $event);
      }
    }
  }), _c('v-row', {
    ref: "property",
    attrs: {
      "align": "center",
      "id": "property"
    }
  }, [_c('v-col', {
    staticClass: "mb-5",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "text-title text-title-2 text-heading-2"
  }, [_vm._v("物件情報")]), _c('div', {
    staticClass: "horizontal-line"
  })])], 1), _c('v-container', [_c('v-row', [_vm._l(_vm.data.sites, function (item, key) {
    return _c('v-col', {
      key: "sitename-".concat(key),
      staticClass: "pb-0 pr-0",
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-btn', {
      class: _vm.tabActive === key ? 'btn-property-tab-active' : 'btn-property-tab',
      attrs: {
        "text": "",
        "depressed": ""
      },
      on: {
        "click": function click($event) {
          _vm.tabActive = key;
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.getSiteName(item)) + " ")])], 1);
  }), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "#757575",
      "min-width": "12",
      "max-width": "12",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.addNewSite();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1)], 2)], 1), _vm._l(_vm.data.sites, function (site, key) {
    return [_vm.tabActive === key ? _c('Site', {
      key: "site-".concat(key),
      attrs: {
        "data": site,
        "prefectures": _vm.allPrefectures,
        "services": _vm.allServices
      },
      on: {
        "update:data": function updateData($event) {
          site = $event;
        }
      }
    }) : _vm._e()];
  })], 2)], 1), _c('div', {
    directives: [{
      name: "intersect",
      rawName: "v-intersect",
      value: _vm.onIntersect,
      expression: "onIntersect"
    }],
    staticClass: "intersection-helper"
  }), _c('div', {
    staticClass: "create-task-footer px-6",
    class: !_vm.isIntersecting ? 'shadow' : ''
  }, [_c('v-row', [_c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "mr-3",
    attrs: {
      "color": _vm.on.customer ? 'rgba(79, 85, 167, 0.45)' : 'rgba(79, 85, 167, 0.1)',
      "depressed": "",
      "rounded": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$vuetify.goTo('#customer', {
          offset: _vm.offset
        });
      }
    }
  }, [_vm._v(" 顧客 ")]), _c('v-btn', {
    staticClass: "mr-3",
    attrs: {
      "color": _vm.on.property ? 'rgba(79, 85, 167, 0.45)' : 'rgba(79, 85, 167, 0.1)',
      "depressed": "",
      "rounded": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$vuetify.goTo('#property', {
          offset: _vm.offset
        });
      }
    }
  }, [_vm._v(" 物件 ")]), _c('v-btn', {
    attrs: {
      "color": _vm.on.work ? 'rgba(79, 85, 167, 0.45)' : 'rgba(79, 85, 167, 0.1)',
      "depressed": "",
      "rounded": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$vuetify.goTo('#work', {
          offset: _vm.offset
        });
      }
    }
  }, [_vm._v(" 作業 ")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/customer/list');
      }
    }
  }, [_vm._v(" キャンセル ")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "white--text",
    attrs: {
      "color": "#4F55A7",
      "width": "100",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" 登録 ")])], 1)], 1)], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }