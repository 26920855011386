<template>
  <div>
    <template v-for="(field, fieldIndex) in filteredFields">
      <div class="info-box pa-8 mb-8" :key="`form-service-type-coating-${fieldIndex}`">
        <v-row v-for="(item, index) in field" :key="`form-service-type-coating-${fieldIndex}-${index}`"
          :align="(item.type === 'checkbox' || item.name === 'partners') ? 'start' : 'center'">
          <v-col cols="2" class="form-header text-right" :class="item.type === 'checkbox' ? '' : ''">
            {{ item.label }}
          </v-col>
          <v-col cols="9">
            <template v-if="item.type === 'radio'">
              <validation-provider v-slot="{ errors }" :name="item.key" rules="">
                <v-radio-group v-model="data[item.key]" row :error-messages="errors" :error="errors.length !== 0"
                  hide-details class="mt-0 radio-sec">
                  <div v-for="(subItem, subIndex) in item.values" :key="`form-service-${item.key}-${subIndex}`"
                    class="d-flex">
                    <template v-if="item.label === '床材種類'">
                      <v-radio :label="subItem" :value="subIndex" @click="florGenreUpdated()">
                      </v-radio>
                    </template>
                    <template v-if="item.label != '床材種類'">
                      <v-radio :label="subItem" :value="subIndex">
                      </v-radio>
                    </template>

                    <template v-if="data[item.key] == 0 && subIndex === 0 && item.label === '床材種類'">
                      <validation-provider v-slot="{ errors }" :name="item.key" rules="max:150">
                        <v-text-field dense outlined class="form-text mr-3" style="max-width: 200px;"
                          :error-messages="errors" :error="errors.length !== 0" v-model="data.floor_genre_memo">
                        </v-text-field>
                      </validation-provider>
                    </template>
                    <template v-if="data[item.key] == 1 && subIndex === 1 && item.label === '床材種類'">
                      <validation-provider v-slot="{ errors }" :name="item.key" rules="max:150">
                        <v-text-field dense outlined class="form-text mr-3" style="max-width: 200px;"
                          :error-messages="errors" :error="errors.length !== 0" v-model="data.floor_genre_memo">
                        </v-text-field>
                      </validation-provider>
                    </template>
                    <template v-if="data[item.key] == 2 && subIndex === 2 && item.label === '床材種類'">
                      <validation-provider v-slot="{ errors }" :name="item.key" rules="max:150">
                        <v-text-field dense outlined class="form-text mr-3" style="max-width: 200px;"
                          :error-messages="errors" :error="errors.length !== 0" v-model="data.floor_genre_memo">
                        </v-text-field>
                      </validation-provider>
                    </template>
                    <template v-if="data[item.key] == 3 && subIndex === 3 && item.label === '床材種類'">
                      <validation-provider v-slot="{ errors }" :name="item.key" rules="max:150">
                        <v-text-field dense outlined class="form-text mr-3" style="max-width: 200px;"
                          :error-messages="errors" :error="errors.length !== 0" v-model="data.floor_genre_memo">
                        </v-text-field>
                      </validation-provider>
                    </template>
                    <template v-if="data[item.key] == 4 && subIndex === 4 && item.label === '床材種類'">
                      <validation-provider v-slot="{ errors }" :name="item.key" rules="max:150">
                        <v-text-field dense outlined class="form-text mr-3" style="max-width: 200px;"
                          :error-messages="errors" :error="errors.length !== 0" v-model="data.floor_genre_memo">
                        </v-text-field>
                      </validation-provider>
                    </template>
                    <template v-if="data[item.key] == 5 && subIndex === 5 && item.label === '床材種類'">
                      <validation-provider v-slot="{ errors }" :name="item.key" rules="max:150">
                        <v-text-field dense outlined class="form-text mr-3" style="max-width: 200px;"
                          :error-messages="errors" :error="errors.length !== 0" v-model="data.floor_genre_memo">
                        </v-text-field>
                      </validation-provider>
                    </template>

                    <template v-if="item.label === '剥離作業' && subItem === 'あり' && data[item.key] === 0">
                      <v-text-field dense outlined class="form-text" style="max-width: 200px;" v-model="data.memo">
                      </v-text-field>
                    </template>
                  </div>
                  <span class="unselect" @click="data[item.key] = null">
                    選択解除
                  </span>
                </v-radio-group>
              </validation-provider>
            </template>

            <template v-else-if="item.type === 'select'">
              <validation-provider v-slot="{ errors }" :name="item.key" rules="">
                <v-select class="form-text" outlined :error-messages="errors" :error="errors.length !== 0"
                  :hide-details="errors.length === 0" dense :placeholder="
                    item?.name === 'partners' ? item.placeholder : item.values
                  " :items="item.name === 'partners' ? partners : item.items" v-model="data[item.key]" item-text="text"
                  :item-value="item.name === 'partners' ? 'text' : 'id'"
                  :multiple="item.name !== 'partners' ? true : false">
                </v-select>
              </validation-provider>
              <div v-if="item.name === 'partners'" class="mt-2">
                <span class="unselect" @click="data[item.key] = null">選択解除</span>
              </div>
              <template v-if="item.name === 'partners' && data[item.key]">
                <v-row class="mt-2">
                  <v-col cols="2" class="form-header text-right">発注範囲</v-col>
                  <v-col cols="10">
                    <v-textarea dense hide-details counter="500" v-model="data.partner_workrange" outlined>
                      <template v-slot:counter="{ props }">
                        {{ props.value }} / {{ props.max }} 文字
                      </template>
                    </v-textarea>
                  </v-col>
                </v-row>
              </template>
            </template>

            <template v-else-if="item.type === 'number' || item.type === 'text'">
              <validation-provider v-slot="{ errors }" :name="item.key" rules="">
                <v-text-field dense outlined class="form-text" :error-messages="errors" :error="errors.length !== 0"
                  :hide-details="errors.length === 0" :type="item.type" v-model="data[item.key]" :suffix="item.append"
                  :placeholder="item.values"></v-text-field>
              </validation-provider>
            </template>

            <template v-else-if="item.type === 'checkbox'">
              <v-row align="center">
                <template v-if="evaluateStatement(item.conditional)">
                  <validation-provider v-slot="{ errors }" :name="item.key" rules="">
                    <v-radio-group :error-messages="errors" :error="errors.length !== 0" row hide-details class="mt-0">
                      <template v-for="(subItem, subIndex) in item.values">
                        <v-checkbox v-if="item.key === 'floor_color'" :key="`checkbox-${item.key}-${subIndex}`"
                          :value="subItem.id" v-model="data[item.key]" class="mx-2 mt-0 mb-2" hide-details>
                          <template v-slot:label>
                            <v-avatar class="coating-blk" :color="
                              subItem.type === 0 ? subItem.color_code : ''
                            ">
                              <img v-if="subItem.type === 1" :src="subItem.image_url" />
                            </v-avatar>
                            <span class="ml-2">
                              {{ subItem.text }}
                            </span>
                          </template>
                        </v-checkbox>
                        <template v-else>
                          <InputCheckbox :key="`checkbox-${item.key}-${subIndex}`" :subItem="subItem"
                            :dataModel.sync="data[item.key]" />
                          <template v-if="
                            subItem.extra_type &&
                            getIndexByItem(data[item.key], subItem) != -1
                          ">
                            <v-text-field :key="`textfield-${item.key}-${subIndex}`" v-if="subItem.extra_type" v-model="
                              data[item.key][
                                getIndexByItem(data[item.key], subItem)
                              ].text_value
                            " dense outlined class="form-text ml-4 mr-4" hide-details height="10" :disabled="
                              getIndexByItem(data[item.key], subItem) === -1
                            " @input="
                              changeInput($event, data[item.key], subItem)
                            "></v-text-field>
                          </template>
                        </template>
                      </template>
                    </v-radio-group>
                  </validation-provider>
                </template>
              </v-row>
            </template>
          </v-col>

          <template v-if="item.extra">
            <v-col v-if="item.extra" cols="auto" class="pr-4">
              {{ item.extra.label }}
            </v-col>
            <v-col v-if="item.extra" cols="auto">
              <v-text-field v-model="data[item.extra.key]" class="form-text" dense hide-details outlined></v-text-field>
            </v-col>
          </template>
        </v-row>
      </div>
    </template>
  </div>
</template>

<script>
import InputCheckbox from '@/components/admin/partials/Customer/View/Basic/Informations/Dialog/EditSite/Partial/Input/InputCheckbox'
import { mapGetters } from 'vuex'
export default {
  name: 'TypeCoatingService',
  components: { InputCheckbox },
  props: {
    data: {
      type: Object,
      default: () => { },
      required: true
    }
  },
  computed: {
    ...mapGetters(['allPartners']),
    filteredFields() {
      return this.fields.map(field => {
        return field.filter(item => {
          if (this.checkCondition(item)) return item
        })
      })
    },
    partners() {
      return this.allPartners.filter(partner => partner.type === 3).map(partner => {
        return {
          id: partner.id,
          text: partner.name
        }
      })
    }
  },
  data() {
    return {
      fields: [
        [
          {
            key: 'coating_genre',
            label: '作業項目',
            type: 'select',
            items: [
              {
                id: 0,
                text: 'プレミアムコートEX'
              },
              {
                id: 1,
                text: 'プレミアムコートEXプラス'
              },
              {
                id: 2,
                text: 'プレミアムコートUVプラス,'
              },
              {
                id: 3,
                text: 'シリコンコーティング'
              },
              {
                id: 4,
                text: '水性コーティング'
              },
              {
                id: 5,
                text: 'プレミアムガード'
              }
            ],
            values: '選択してください。'
          },
          {
            key: 'coating_area',
            label: '面積',
            type: 'number',
            append: '㎡',
            values: '0000000'
          },
          {
            key: 'coating_range1',
            label: '作業範囲',
            type: 'checkbox',
            // conditional: {
            //   field: 'data.coating_genre',
            //   operator: '<=',
            //   value: 2
            // },
            values: [
              {
                text: 'LDK',
                value: '0'
              },
              {
                text: 'ホール廊下',
                value: '1'
              },
              {
                text: '階段',
                value: '2'
              },
              {
                text: '2階ホール',
                value: '3'
              },
              {
                id: 1,
                extra_type: 'with_text',
                text: '洋室',
                value: '4'
              },
              {
                id: 2,
                extra_type: 'with_text',
                text: '洋室',
                value: '5'
              },
              {
                id: 3,
                extra_type: 'with_text',
                text: '洋室',
                value: '6'
              },
              {
                id: 4,
                extra_type: 'with_text',
                text: '洋室',
                value: '7'
              },
              {
                id: 5,
                extra_type: 'with_text',
                text: 'その他',
                value: '8'
              },
              {
                text: 'トイレ',
                value: '9'
              }
            ]
          },
          {
            key: 'coating_range2',
            label: '作業範囲',
            type: 'checkbox',
            conditional: {
              field: 'data.coating_genre',
              operator: '===',
              value: 3
            },
            values: [
              {
                text: 'コインランドリー',
                value: '0'
              },
              {
                text: '店舗',
                value: '1'
              },
              {
                extra_type: 'with_text',
                text: '他',
                value: '2'
              }
            ]
          },
          {
            key: 'floor_color',
            label: '床材の色',
            type: 'checkbox',
            values: []
          },
          {
            key: 'floor_genre',
            label: '床材種類',
            type: 'radio',
            values: [
              '3溝突板',
              '2溝突板',
              '1溝タイプ（フラット）',
              '1溝タイプ（ハピアタイプ）',
              '1溝タイプ（木目強調タイプ）',
              '化学床'
            ]
          },
          {
            key: 'tough_sealer',
            label: 'タフシーラー',
            type: 'radio',
            values: ['30%', '100%', '150%', 'なし']
          },
          {
            key: 'removal_work',
            label: '剥離作業',
            type: 'radio',
            values: ['あり', 'なし']
          },
          {
            key: 'micro_mop',
            label: 'マイクロモップ',
            type: 'radio',
            values: ['あり', 'なし']
          },
          {
            key: 'premium_cleaner',
            label: 'プレミアム専用クリーナー',
            type: 'radio',
            values: ['あり', 'なし']
          },
          {
            key: 'counter_table',
            label: '多目的カウンター',
            type: 'radio',
            values: ['あり', 'なし']
          },
          {
            label: '外部発注',
            key: 'assigned_partner',
            type: 'select',
            name: 'partners',
            placeholder: '協力会社を選択',
            extra_type: 'textarea'
          },
        ],
        [
          {
            key: 'premium_guard',
            label: 'プレミアムガード',
            type: 'checkbox',
            values: [
              {
                text: '浴室',
                value: '0'
              },
              {
                text: 'キッチン',
                value: '1'
              },
              {
                text: '洗面所',
                value: '2'
              },
              {
                text: 'ワークトップ天板',
                value: '3'
              },
              {
                text: 'トイレ1F',
                value: '4'
              },
              {
                text: 'トイレ2F',
                value: '5'
              },
              {
                extra_type: 'with_text',
                text: 'その他',
                value: '6'
              }
            ]
          },
          {
            key: 'campaign',
            label: 'キャンペーンサービス',
            type: 'text',
            values: 'キャンペーンサービス'
          },
          {
            key: 'coating_option',
            label: 'サービス施工',
            type: 'checkbox',
            values: [
              {
                text: 'クローゼット（収納）',
                value: '0'
              },
              {
                text: '玄関石材',
                value: '1'
              },
              {
                text: '光触媒スプレー',
                value: '2'
              },
              {
                text: 'カウンター',
                value: '3'
              },
              {
                text: 'ドアノブ抗ウイルス',
                value: '4'
              },
              {
                text: 'キズ補修',
                value: '5'
              },
              {
                id: 1,
                extra_type: 'with_text',
                text: 'その他',
                value: '6'
              }
            ]
          }
        ]
      ]
    }
  },
  created() {
    this.getFloorMaterialsData()
  },

  methods: {
    florGenreUpdated() {
      this.data.floor_genre_memo = null
    },
    checkCondition(item) {
      if (item.conditional) {
        return this.evaluateStatement(item.conditional)
      }
      return true
    },
    evaluateStatement(conditional) {
      if (conditional !== undefined) {
        let str = `this.${conditional.field} ${conditional.operator} ${conditional.value}`
        return eval(str)
      }
      return true
    },
    getIndexByItem(data, item) {
      return data
        .map(item => `${item.text}${item.id}`)
        .indexOf(`${item.text}${item.id}`)
    },
    changeInput(e, data, item) {
      data[this.getIndexByItem(data, item)].text_value = e
    },

    getFloorMaterialsData() {
      let params = {}
      params.sort_by = 'display_order';
      this.$store.dispatch('FLOOR_MATERIAL_GET_ALL', params).then(res => {
        this.fields[0].find(
          f => f.key === 'floor_color'
        ).values = res.data.data.floor_materials.map(item => {
          return {
            id: item.id,
            text: item.name,
            type: item.type,
            color_code: item.color_code,
            image_url: item.image_url,
            value: item.id
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.info-box {
  background: #f8f9ff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  &-headline {
    color: #4f55a7;
    font-size: 18px;

    &-sub {
      color: #474747;
      font-size: 18px;
      font-weight: bold;
    }
  }

  &-dot {
    background-color: #4f55a7;
    height: 3px;
    width: 3px;
    border-radius: 50%;
    display: inline-block;
  }

  &-work-information {
    &-headline {
      color: #333333;

      &.top {
        font-size: 18px;
        font-weight: 700;
      }

      &.sub {
        font-size: 14px;
      }
    }

    &-content {
      &-label {
        color: #828282;
        font-size: 16px;
      }

      &-content {
        color: #333333;
        font-size: 16px;
      }
    }
  }

  .form {
    &-header {
      color: #393a00;
    }

    &-text {
      box-shadow: unset;
      max-width: 200px;

      ::v-deep {
        .v-input__slot {
          background: #ffffff;
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
          min-height: 36px !important;
          margin-top: 5px;
        }

        .v-select__selections {
          padding: 2px 0 !important;
        }
      }
    }
  }
}

.unselect {
  margin-top: 4px;
  cursor: pointer;
  font-size: 90%;
  color: #cf2600;
}

.coating-blk {
  width: 100px !important;
  height: 65px !important;
  border-radius: 5%;
}
</style>
