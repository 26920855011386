export default {
  site_id: '',
  name: '',
  postcode: '',
  prefecture: '',
  city: '',
  address: '',
  phone: '',
  memo: '',
  lat: null,
  lng: null,
  services: [],
  project: {
    service_type_id: null,
    partner_0_id: '',
    partner_1_id: '',
    partner_2_id: '',
    on_site_monitoring: null,
    general_guideline: '',
    elevator: null,
    site_age: null,
    residents: null,
    furnitures: null,
    parking: null,
    parking_text: '',
    key: [],
    key_text: '',
    lightening_braker: null,
    outlet: null,
    electrical_issues: null,
    electrical_issues_text: '',
    shutter: null,
    curtain: null,
    air_conditioner: null
  },
  svc_coating: {
    coating_genre: null,
    coating_area: null,
    coating_range1: [],
    coating_range2: [],
    floor_color: [],
    floor_genre: null,
    removal_work: null,
    micro_mop: null,
    premium_cleaner: null,
    premium_guard: [],
    campaign: '',
    coating_option: [],
    tough_sealer: null
  },
  svc_bldg_mnt: {
    floor: {
      prep: [],
      detergent: [],
      detergent_method: [],
      cleaning_method: [],
      polisher: [],
      cleaning_machine: [],
      pat: [],
      certain_place_method: [],
      certain_place_explanation: '',
      waxing_place: {
        waxing_place_all: []
        // waxing_place_part_1: [],
        // waxing_place_part_2: [],
        // waxing_place_part_3: [],
        // waxing_place_part_4: [],
      },
      waxing_direction: {
        waxing_place_all_direction: ''
        // waxing_place_part_1_direction: '',
        // waxing_place_part_2_direction: '',
        // waxing_place_part_3_direction: '',
        // waxing_place_part_4_direction: '',
      },
      waxing_product: [],
      waxing_rule: ''
    },
    window: {
      stepladder: [],
      extension_pole: [],
      safety_helmet: [],
      safety_belt: [],
      outside_radio: 1,
      outside: [],
      inside: [],
      certain_window_method: [],
      certain_window_explanation: '',
      certain_window_rule: ''
    },
    carpet: {
      prep: [],
      pre_task: [],
      washing: [],
      machine: [],
      stain_removal: [],
      wastewater: []
    }
  },
  svc_other: {
    task_description_1: '',
    task_description_2: '',
    task_description_3: ''
  }
}
